import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
moment.locale('pt-br');
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Pipe({
  name: 'timeAgo'
})
export class TimeagoPipe implements PipeTransform {

  transform(timestamp: firebase.firestore.Timestamp) {
    return moment(timestamp.toDate()).fromNow(true)
  }
}
