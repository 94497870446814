import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../../services/firestore.service';

@Component({
  selector: 'app-dashboard-charts-moods',
  templateUrl: './dashboard-charts-moods.component.html',
  styleUrls: ['./dashboard-charts-moods.component.scss']
})
export class DashboardChartsMoodsComponent implements OnInit {

  periods = ["annually", "daily", "monthly"]
  chart

  constructor(private firestore: FirestoreService) {}

  ngOnInit() {
    this.data('monthly')
  }

  data(period: string) {
    let path = `moods-${period}`
    this.firestore.stats().doc(path).valueChanges().subscribe(stats => {
      this.chart = {
        toolbox: {
          show : true,
          feature : {
            saveAsImage : {
              show : true,
              title : 'save',
              type : 'png',
            }
          }
        },
        calculable : true,
        xAxis : [
          {
            type : 'category',
            boundaryGap : false,
            data : stats ? Object.keys(stats) : []
          }
        ],
        yAxis : [
          {
            type : 'value'
          },
          {
            type : 'value'
          }
        ],
        series : [
            {
              type: 'line',
              smooth: true,
              itemStyle: {normal: {areaStyle: {type: 'default'}}},
              data: stats ? Object.keys(stats).map(i => stats[i]).map(value => value['sentimentValue']/value['count']) : []
            }
          ]
        }
    })
  }
}
