import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../../../../../../models/user';
import { FirestoreService } from '../../../../../../services/firestore.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare let echarts: any;

@Component({
  selector: 'app-charts-by-user',
  templateUrl: './charts-by-user.component.html',
  styleUrls: ['./charts-by-user.component.scss']
})
export class ChartsByUserComponent implements OnChanges {

  @Input() user: User
  feedbacksReceived
  skillsRattingSentimentValue

  constructor(private firestore: FirestoreService, private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.feedbacksReceivedData(changes.user.currentValue.id)
    this.skillsRattingSentimentValueData(changes.user.currentValue.id)
  }

  async feedbacksReceivedData(userId: string) {
    const values = await this.firestore.usersStats(userId).doc('feedbacks-received-rating-sentiment-value').valueChanges().pipe(first()).toPromise()
    let strings = {
      awesomeCount: await this.translate.get("dashboard.awesomeCount").pipe(first()).toPromise(),
      goodCount: await this.translate.get("dashboard.goodCount").pipe(first()).toPromise(),
      averageCount: await this.translate.get("dashboard.averageCount").pipe(first()).toPromise(),
      badCount: await this.translate.get("dashboard.badCount").pipe(first()).toPromise(),
      worstCount: await this.translate.get("dashboard.worstCount").pipe(first()).toPromise(),
    }
    this.feedbacksReceived = {
      color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
      tooltip : {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      toolbox: {
        show : true,
        feature : {
          mark : { show: true },
          magicType : {
            show: true,
            type: ['pie', 'funnel']
          },
          saveAsImage : {
            show : true,
            title : 'save',
            type : 'png',
            lang : ['pt-BR']
          }
        }
      },
      calculable : true,
      series : [
        {
          name: '增值电信业务统计表',
          type: 'pie',
          radius : [40, 150],
          roseType : 'area',
          data: Object.keys(values).map(i => {
            return { value: values[i], name: strings[i] }
          }).sort(function (a, b) { return a.value - b.value; })
        }
      ]
    };
  }

  async skillsRattingSentimentValueData(userId: string) {
    const values = await this.firestore.usersStats(userId).doc('skills-rating-sentiment-value').valueChanges().pipe(first()).toPromise()
    this.skillsRattingSentimentValue = {
      title : {
        text: '',
        subtext: ''
      },
      tooltip : {
        trigger: 'axis'
      },
      legend: {
        orient : 'vertical',
        x : 'right',
        y : 'bottom',
      },
      toolbox: {
        show : true,
        feature : {
          saveAsImage : {
            show : true,
            title : 'save',
            type : 'png',
            lang : ['pt-BR']
          }
        }
      },
      radar : [
        {
          radius: 120,
          indicator : values ? Object.keys(values).map(i => values[i]).map(value => { return { name: value['name'], max: 1}}): []
        }
      ],
      calculable : true,
      series : [
        {
          name: '',
          type: 'radar',
          itemStyle: {
            normal: {
              areaStyle: {
                normal: {
                  opacity: 0.9,
                  color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                    {
                      color: '#B8D3E4',
                      offset: 0
                    },
                    {
                      color: '#72ACD1',
                      offset: 1
                    }
                  ])
                }
              }
            }
          },
          data : [
            {
              value : values ? Object.keys(values).map(i => values[i]).map(value => value['sentimentValue']/value['count']): [],
              name : ''
            }
          ]
        }
      ]
    }
  }
}
