import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from './services/firestore.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  @HostBinding('class.loading') loading = true;

  constructor(private router: Router, private firestore: FirestoreService) {
    this.loading = false;
  }
}
