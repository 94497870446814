import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../../services/firestore.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-charts-contexts-rating-sentiment-value',
  templateUrl: './dashboard-charts-contexts-rating-sentiment-value.component.html',
  styleUrls: ['./dashboard-charts-contexts-rating-sentiment-value.component.scss']
})
export class DashboardChartsContextsRatingSentimentValueComponent implements OnInit {

  contexts
  type = 'count'

  constructor(private firestore: FirestoreService) { }

  ngOnInit() {
    this.contexts = this.firestore.stats().doc('contexts-rating-sentiment-value').valueChanges().pipe(map(values => {
      return Object.keys(values).map(i => values[i])
    }))
  }
}