import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { Group } from '../models/group';
import { Context } from '../models/context';
import { Skill } from '../models/skill';
import { Organization } from '../models/organization';
import { environment } from '../../environments/environment';
import { ReasonDecline } from '../models/reason-decline';
import { Survey } from '../models/survey';
import { Notification } from '../models/notification';
import { Feedback } from '../models/feedback';
import { Message } from '../models/message';
import { Device } from '../models/device';
import { ImportUsers } from '../models/import-users';

@Injectable()
export class FirestoreService {

  constructor(public firestore: AngularFirestore, public auth: AuthService) { }

  organizations(queryFn?: QueryFn) {
    return this.firestore.collection<Organization>('organizations', queryFn)
  }

  organization() {
    return this.organizations().doc<Organization>(environment.firebase.projectId)
  }

  users(queryFn?: QueryFn) {
    return this.organization().collection<User>('users', queryFn)
  }

  user() {
    return this.users().doc<User>(this.auth.getUid())
  }

  groups(queryFn?: QueryFn) {
    return this.organization().collection<Group>('groups', queryFn)
  }

  contextsBase(queryFn?: QueryFn) {
    return this.organization().collection<Context>('contexts', queryFn)
  }

  skillsBase(queryFn?: QueryFn) {
    return this.organization().collection<Skill>('skills', queryFn)
  }

  feedbacksRequestsReasonsDecline(queryFn?: QueryFn) {
    return this.organization().collection<ReasonDecline>('feedbacks-requests-reasons-decline', queryFn)
  }

  messagesContexts(queryFn?: QueryFn) {
    return this.organization().collection<Context>('messages-contexts', queryFn)
  }
  
  surveys(queryFn?: QueryFn) {
    return this.organization().collection<Survey>('surveys', queryFn)
  }

  surveysUsers(surveyId: string, queryFn?: QueryFn) {
    return this.surveys().doc(surveyId).collection<User>('users', queryFn)
  }

  notifications(queryFn?: QueryFn) {
    return this.organization().collection<Notification>('notifications', queryFn)
  }

  feedbacks(queryFn?: QueryFn) {
    return this.organization().collection<Feedback>('feedbacks', queryFn)
  }

  messages(queryFn?: QueryFn) {
    return this.organization().collection<Message>('messages', queryFn)
  }

  devices(queryFn?: QueryFn) {
    return this.user().collection<Device>('devices', queryFn)
  }

  imports(queryFn?: QueryFn) {
    return this.organization().collection<ImportUsers>('imports', queryFn)
  }
  
  stats() {
    return this.organization().collection("stats")
  }

  usersStats(userId: string) {
    return this.users().doc(userId).collection("stats")
  }

  groupsStats(groupId: string) {
    return this.groups().doc(groupId).collection("stats")
  }

  membersStats(groupId: string, userId: string) {
    return this.groups().doc(groupId).collection("members").doc(userId).collection("stats")
  }
}
