import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../../../services/firestore.service';
import { AuthService } from '../../../../../services/auth.service';

@Component({
  selector: 'app-performance-charts-moods',
  templateUrl: './performance-charts-moods.component.html',
  styleUrls: ['./performance-charts-moods.component.scss']
})
export class PerformanceChartsMoodsComponent implements OnInit {

  periods = ["annually", "daily", "monthly"]
  chart

  constructor(
    private firestore: FirestoreService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.data('monthly')
  }

  data(period: string) {
    let path = `moods-${period}`
    this.firestore.stats().doc(path).valueChanges().subscribe(stats => {
      this.firestore.usersStats(this.auth.getUid()).doc(path).valueChanges().subscribe(usersStats => {
        this.chart = {
          toolbox: {
            show : true,
            feature : {
              saveAsImage : {
                show : true,
                title : 'save',
                type : 'png',
              }
            }
          },
          calculable : true,
          xAxis : [
            {
              type : 'category',
              boundaryGap : false,
              data : stats ? Object.keys(stats) : []
            },
            {
              type : 'category',
              boundaryGap : false,
              data : usersStats ? Object.keys(usersStats) : []
            }
          ],
          yAxis : [
            {
              type : 'value'
            },
            {
              type : 'value'
            }
          ],
          series : [
              {
                type: 'line',
                smooth: true,
                itemStyle: {normal: {areaStyle: {type: 'default'}}},
                data: stats ? Object.keys(stats).map(i => stats[i]).map(value => { return value['sentimentValue']/value['count']}) : []
              },
              {
                type: 'line',
                smooth: true,
                itemStyle: {normal: {areaStyle: {type: 'default'}}},
                data: usersStats ? Object.keys(usersStats).map(i => usersStats[i]).map(value => { return value['sentimentValue']/value['count']}) : []
              }
          ]
          }
      })
    })
  }
}
