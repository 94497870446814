import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../../services/firestore.service';
import { Group } from '../../../../models/group';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-charts-feedbacks-received-rating-sentiment-value',
  templateUrl: './dashboard-charts-feedbacks-received-rating-sentiment-value.component.html',
  styleUrls: ['./dashboard-charts-feedbacks-received-rating-sentiment-value.component.scss']
})
export class DashboardChartsFeedbacksReceivedRatingSentimentValueComponent implements OnInit {

  groups: Observable<Group[]>
  chart

  constructor(private firestore: FirestoreService, private translate: TranslateService) {}

  ngOnInit() {
    this.groups = this.firestore.groups().valueChanges()
    this.data()
  }

  async data(){
    let path = 'feedbacks-received-rating-sentiment-value'
    let ref = this.firestore.stats().doc(path)
    let strings = {
      awesomeCount: await this.translate.get("dashboard.awesomeCount").pipe(first()).toPromise(),
      goodCount: await this.translate.get("dashboard.goodCount").pipe(first()).toPromise(),
      averageCount: await this.translate.get("dashboard.averageCount").pipe(first()).toPromise(),
      badCount: await this.translate.get("dashboard.badCount").pipe(first()).toPromise(),
      worstCount: await this.translate.get("dashboard.worstCount").pipe(first()).toPromise(),
    }
    ref.valueChanges().subscribe(values => {
        this.chart = {
          color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
          tooltip : {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          toolbox: {
            show : true,
            feature : {
              mark : { show: true },
              magicType : {
                show: true,
                type: ['pie', 'funnel']
              },
              saveAsImage : {
                show : true,
                title : 'save',
                type : 'png',
              }
            }
          },
          calculable : true,
          series : [
            {
              name: '',
              type: 'pie',
              radius : [40, 150],
              roseType : 'area',
              data: values ? Object.keys(values).map(i => {
                return { value: values[i], name: strings[i] }
              }).sort((a, b) => { return a.value - b.value; }) : []
            }
          ]
      };
    })
  }
}