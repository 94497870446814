import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { routerAnimation } from '../../utils/page.animation';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../modules/alerts/services/alerts.service';
import { Organization } from '../../models/organization';
import { FirestoreService } from '../../services/firestore.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerAnimation]
})
export class LoginComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;
  organization: Observable<Organization>
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private firestore: FirestoreService,
    private alert: AlertsService
  ) {}

  ngOnInit() {

    this.organization = this.firestore.organization().valueChanges()
    this.auth.auth.authState.subscribe(user => {
      if(user) {
        const { returnUrl } = this.activatedRoute.snapshot.queryParams
        this.router.navigate([returnUrl ? returnUrl : '']);
      }
    })
  }

  async login(email: string, password: string) {
    const loading = this.alert.loading('Carregando...')
    try {
      await this.auth.signInWithEmailAndPassword(email, password)
      loading.close()
    } catch (error) {
      loading.close()
      switch (error.code) {
        case 'auth/user-not-found':
        this.alert.open('Ocorreu um erro!', 'Não há registro de usuário correspondente a este email.')
        return
        case 'auth/invalid-email':
        this.alert.open('Ocorreu um erro!', 'O endereço de e-mail está mal formatado.')
        return
        case 'auth/wrong-password':
        this.alert.open('Ocorreu um erro!', 'A senha é inválida.')
        return
        default: this.alert.open('Ocorreu um erro!', error)
      }
    }
  }
}