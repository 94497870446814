import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { initFirebaseBackend } from '../../utils/authUtils';
import { AuthService } from '../../services/auth.service';
import { UploadService } from '../../services/upload.service';
import { FunctionsService } from '../../services/functions.service';
import { AuthenticationService } from '../../utils/AuthenticationService';
import { FirestoreService } from '../../services/firestore.service';
import { MessagingService } from '../../services/messaging.service';

initFirebaseBackend(environment.firebase);

@NgModule({
  declarations: [],
  imports: [
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  exports: [
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    AuthService,
    UploadService,
    FunctionsService,
    AuthenticationService,
    FirestoreService,
    MessagingService,
    // {
    //   provide: FirestoreSettingsToken,
    //   useValue: environment.production ? undefined : {
    //     host: 'localhost:8080',
    //     ssl: false
    //   }
    // },
    // { provide: ORIGIN, useValue: 'http://localhost:5001' }
  ],
})
export class FirebaseModule { }
