import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './components/alerts/alerts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
import { ResizeModule } from '../resize.module';
import { TranslateSharedModule } from '../translate/translate.module';
import { AlertsService } from './services/alerts.service';

@NgModule({
  declarations: [AlertsComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    HttpClientModule,
    NgxEchartsModule,
    ResizeModule,
    TranslateSharedModule,
  ],
  entryComponents: [
    AlertsComponent
  ],
  providers: [
    AlertsService
  ],
  exports: [AlertsComponent]
})
export class AlertsModule { }
