import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';
import { FirestoreService } from '../services/firestore.service';
import { AlertsService } from '../modules/alerts/services/alerts.service';

@Injectable({ providedIn: 'root' })
export class UserAuthGuard implements CanActivate {

  constructor(
    private router: Router, 
    private auth: AuthService,
    private firestore: FirestoreService,
    private alert: AlertsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.auth.auth.user.pipe(first()).toPromise()
    if(user){
        const { role } = await this.firestore.user().valueChanges().pipe(first()).toPromise()
        if(role == 'admin') {
          this.router.navigate(['/admin']);
        }else if(role == 'internal') {
          return true
        }else {
          this.auth.auth.auth.signOut()
          this.alert.open("Atenção","Você não tem permissão para acessar esta página.")
          this.router.navigate(['/login']);
          return false;
        }
    }else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
  }
}
