import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FunctionsService {

  constructor(private functions: AngularFireFunctions) {}

  createUser(user: User) {
    const call = this.functions.httpsCallable<{user: User, organizationId: string}, { error: boolean, message: string }>('usersCallController-create')
    return call({user, organizationId: environment.firebase.projectId})
  }

  updateUser(user: User, organizationId?: string) {
    const call = this.functions.httpsCallable<{user: User, organizationId: string}, { error: boolean, message: string }>('usersCallController-update')
    return call({user, organizationId: organizationId ? organizationId : environment.firebase.projectId})
  }

  deleteUser(userId: string, organizationId?: string) {
    const call = this.functions.httpsCallable<{userId: string, organizationId: string}, { error: boolean, message: string }>('usersCallController-delete')
    return call({userId, organizationId: organizationId ? organizationId : environment.firebase.projectId})
  }

  createFeedbacksMock(qty: number, groupId: string, organizationId?: string) {
    return this.functions.httpsCallable<{ qty: number, organizationId: string, groupId: string }, { error: boolean, message: string }>('feedbacksMockCallController-create')({qty, organizationId: organizationId ? organizationId : environment.firebase.projectId, groupId})
  }
}