import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FirestoreService } from '../../../../services/firestore.service';
import { User } from '../../../../models/user';

@Component({
  selector: 'app-dashboard-charts-feedbacks-by-users',
  templateUrl: './dashboard-charts-feedbacks-by-users.component.html',
  styleUrls: ['./dashboard-charts-feedbacks-by-users.component.scss']
})
export class DashboardChartsFeedbacksByUsersComponent {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<User[]>;
  users: User[] = [];
  allUsers: User[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private firestore: FirestoreService) {
    this.firestore.users().valueChanges().subscribe(users => {
      this.allUsers = users
      this.filteredUsers = this.userCtrl.valueChanges.pipe(startWith(null), map(user => {
        if (typeof user === 'string') { return this._filter(user) }
      }))
    })
  }

  remove(user: User): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
      this.users =  Object.assign([], this.users);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    const source = event.source;

    if (value) {
      this.users.push(event.option.value);
      this.users =  Object.assign([], this.users);
      this.userInput.nativeElement.value = '';
      this.userCtrl.setValue(null);
    }
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.allUsers.filter(user => user.name.toLowerCase().indexOf(filterValue) === 0 && !this.users.includes(user));
  }
}