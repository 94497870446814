import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { routerAnimation } from '../../../utils/page.animation';
import { AuthService } from '../../../services/auth.service';
import { Organization } from '../../../models/organization';
import { FirestoreService } from '../../../services/firestore.service';

@Component({
  selector: 'app-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss'],
  animations: [routerAnimation]
})
export class LoginForgotPasswordComponent {

  @HostBinding('@routerAnimation') routerAnimation = true;
  organization: Observable<Organization>

  constructor(
    private auth: AuthService,
    private router: Router,
    private firestore: FirestoreService,
    private alert: MatSnackBar
  ) {
    this.organization = firestore.organization().valueChanges()
  }

  async reset(email: string) {
    try {
      await this.auth.resetPassword(email)
      this.alert.open('Email de reset de senha enviado com sucesso!', null, { duration: 2000 })
    } catch (error) {
      this.alert.open('Ocorreu um erro tente mais tarde.', null, { duration: 2000 })
    }
  }
}
