import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MainUserComponent } from './pages/main/main-user/main-user.component';
import { MainAdminComponent } from './pages/main/main-admin/main-admin.component';
import { PerformanceComponent } from './pages/performance/performance/performance.component';
import { LoginForgotPasswordComponent } from './pages/login/login-forgot-password/login-forgot-password.component';
import { SafePipe } from './modules/time-ago/pipes/safe.pipe';
import { AppRoutesModule } from './app.routes';
import { SidemenuModule } from './modules/sidemenu/sidemenu.module';
import { ResizeModule } from './modules/resize.module';
import { LoginLinkComponent } from './pages/login/login-link/login-link.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateSharedModule } from './modules/translate/translate.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AlertsModule } from './modules/alerts/alerts.module';
import { MomentModule } from 'angular2-moment/moment.module';
import { TimeAgoModule } from './modules/time-ago/time-ago.module';
import { FirebaseModule } from './modules/firebase/firebase.module';
import { PerformanceChartsFeedbacksReceivedComponent } from './pages/performance/performance/components/performance-charts-feedbacks-received/performance-charts-feedbacks-received.component';
import { PerformanceChartsSkillsRattingSentimentValueComponent } from './pages/performance/performance/components/performance-charts-skills-ratting-sentiment-value/performance-charts-skills-ratting-sentiment-value.component';
import { PerformanceChartsMoodsComponent } from './pages/performance/performance/components/performance-charts-moods/performance-charts-moods.component';
import { DashboardChartsContextsRatingSentimentValueComponent } from './pages/dashboard/components/dashboard-charts-contexts-rating-sentiment-value/dashboard-charts-contexts-rating-sentiment-value.component';
import { DashboardChartsSkillsRatingSentimentValueComponent } from './pages/dashboard/components/dashboard-charts-skills-rating-sentiment-value/dashboard-charts-skills-rating-sentiment-value.component';
import { DashboardChartsMoodsComponent } from './pages/dashboard/components/dashboard-charts-moods/dashboard-charts-moods.component';
import { DashboardChartsFeedbacksReceivedRatingSentimentValueComponent } from './pages/dashboard/components/dashboard-charts-feedbacks-received-rating-sentiment-value/dashboard-charts-feedbacks-received-rating-sentiment-value.component';
import { DashboardChartsFeedbacksByUsersComponent } from './pages/dashboard/components/dashboard-charts-feedbacks-by-users/dashboard-charts-feedbacks-by-users.component';
import { DashboardChartsFeedbacksByGroupsComponent } from './pages/dashboard/components/dashboard-charts-feedbacks-by-groups/dashboard-charts-feedbacks-by-groups.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule } from 'saturn-datepicker'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ChartsByUserComponent } from './pages/dashboard/components/dashboard-charts-feedbacks-by-users/components/charts-by-user/charts-by-user.component';
import { ChartsByGroupComponent } from './pages/dashboard/components/dashboard-charts-feedbacks-by-groups/components/charts-by-group/charts-by-group.component'

@NgModule({
  declarations: [
    AppComponent,
    MainUserComponent,
    MainAdminComponent,
    LoginComponent,
    LoginForgotPasswordComponent,
    SafePipe,
    DashboardComponent,
    PerformanceComponent,
    PrivacyPolicyComponent,
    LoginLinkComponent,
    NotFoundComponent,
    PerformanceChartsMoodsComponent,
    PerformanceChartsFeedbacksReceivedComponent,
    PerformanceChartsSkillsRattingSentimentValueComponent,
    DashboardChartsContextsRatingSentimentValueComponent,
    DashboardChartsSkillsRatingSentimentValueComponent,
    DashboardChartsMoodsComponent,
    DashboardChartsFeedbacksReceivedRatingSentimentValueComponent,
    DashboardChartsFeedbacksByUsersComponent,
    DashboardChartsFeedbacksByGroupsComponent,
    ChartsByUserComponent,
    ChartsByGroupComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    SidemenuModule,
    NgxEchartsModule,
    ResizeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateSharedModule,
    AppRoutesModule,
    AlertsModule,
    MomentModule,
    TimeAgoModule,
    FirebaseModule,
    SatDatepickerModule
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
],
  bootstrap: [AppComponent]
})
export class AppModule {}
