import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../../../../services/firestore.service';
import { Group } from '../../../../../models/group';
import { Organization } from '../../../../../models/organization';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-performance-charts-feedbacks-received',
  templateUrl: './performance-charts-feedbacks-received.component.html',
  styleUrls: ['./performance-charts-feedbacks-received.component.scss']
})
export class PerformanceChartsFeedbacksReceivedComponent implements OnInit {

  groups: Observable<Group[]>
  organization: Organization
  chart

  constructor(
    private firestore: FirestoreService, 
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.organization = await this.firestore.organization().valueChanges().pipe(first()).toPromise()
    this.groups = this.firestore.groups().valueChanges()
    this.data(this.organization)
  }

  isGroup(object: any): object is Group {
    return 'managers' in object;
  }

  async data(data?: Group | Organization){
    const path = 'feedbacks-received-rating-sentiment-value'
    var ref = this.firestore.stats().doc(path)
    let strings = {
      awesomeCount: await this.translate.get("dashboard.awesomeCount").pipe(first()).toPromise(),
      goodCount: await this.translate.get("dashboard.goodCount").pipe(first()).toPromise(),
      averageCount: await this.translate.get("dashboard.averageCount").pipe(first()).toPromise(),
      badCount: await this.translate.get("dashboard.badCount").pipe(first()).toPromise(),
      worstCount: await this.translate.get("dashboard.worstCount").pipe(first()).toPromise(),
    }
    if(this.isGroup(data)) {
      ref = this.firestore.groupsStats(data.id!!).doc(path)
    }
    const values = await ref.valueChanges().pipe(first()).toPromise()
    this.chart = {
      color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
      tooltip : {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      toolbox: {
        show : true,
        feature : {
          mark : { show: true },
          magicType : {
            show: true,
            type: ['pie', 'funnel']
          },
          saveAsImage : {
            show : true,
            title : 'save',
            type : 'png',
          }
        }
      },
      calculable : true,
      series : [
        {
          name: '',
          type: 'pie',
          radius : [40, 150],
          roseType : 'area',
          data: values ? Object.keys(values).map(i => {
            return { value: values[i], name: strings[i] }
          }).sort(function (a, b) { return a.value - b.value; }) : []
        }
      ]
  };
  }
}