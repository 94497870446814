import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertsComponent } from '../components/alerts/alerts.component';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(public dialog: MatDialog) { }

  open(title?: string, message?: string) {
    return this.dialog.open(AlertsComponent, {
      width: '250px',
      data: {
        title: title ? title : 'Atenção',
        message: message ? message : '',
        loading: false
      }
    });
  }

  loading(message?: string) {
    return this.dialog.open(AlertsComponent, {
      width: '250px',
      data: {
        message: message ? message : 'Carregando...',
        loading: true
      },
      disableClose: true
    });
  }
}
