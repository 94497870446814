import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private messaging: AngularFireMessaging) { }

  requestPermission() {
    this.messaging.requestPermission
    .pipe(mergeMapTo(this.messaging.tokenChanges))
    .subscribe(
      (token) => { console.log('Permission granted! Save to the server!', token); },
      (error) => { console.error(error); },  
    );
  }

  requestToken() {
    this.messaging.requestToken
    .subscribe(
      (token) => { console.log('Permission granted! Save to the server!', token); },
      (error) => { console.error(error); },  
    );
  }

  deleteToken() {
    this.messaging.getToken
      .pipe(mergeMap(token => this.messaging.deleteToken(token)))
      .subscribe(
        (token) => { console.log('Token deleted!'); },
      );
  }

  listen() {
    this.messaging.messages
      .subscribe((message) => { console.log(message); });
  }
}
