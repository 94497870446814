import { Injectable } from '@angular/core';
import { User } from './auth.models';
import { getFirebaseBackend } from './authUtils';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;
    constructor() {
    }
    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }
    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    updatePassword(password: string, newPassword: string) {
        return getFirebaseBackend().updatePassword(password, newPassword).then((response: any) => {
            const user = response;
            return user;
        });
    }
    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }
    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
}
