import { Component, HostBinding, OnInit } from '@angular/core';
import { routerAnimation } from '../../utils/page.animation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [routerAnimation]
})

export class DashboardComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;

  constructor() {}

  ngOnInit() {}
}