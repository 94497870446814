import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ResizeService } from '../../../services/resize.service';
import { routerAnimation } from '../../../utils/page.animation';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { Organization } from '../../../models/organization';

@Component({
  selector: 'app-main-user',
  templateUrl: './main-user.component.html',
  styleUrls: ['./main-user.component.scss'],
  animations: [routerAnimation]
})
export class MainUserComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = false;
  @HostBinding('class.dark-theme') darkTheme = false;
  _sidenavMode = 'side';
  _boxedLayout = false;
  sideNavOpened = false;
  heightView = window.innerHeight - 50;
  user: Observable<User>;
  organization: Observable<Organization>;
  resume

  constructor(
    private resizeService: ResizeService,
    private auth: AuthService,
    private firestore: FirestoreService,
    private router: Router
  ) {}

  ngOnInit() {
    this.auth.auth.authState.subscribe(user => {
      if (!user) return this.router.navigate(['login'])
      this.user = this.firestore.user().valueChanges()
      this.organization = this.firestore.organization().valueChanges()
      this.organization.subscribe(org => document.querySelector('body').classList.add('dark-theme'))
      this.resume = this.firestore.usersStats(user.uid).valueChanges()
      this.onResize();
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
    setTimeout(() => this.sideNavOpened = true, 0);
  }

  logout() {
    this.auth.logout()
  }

  set sidenavMode(val) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get sidenavMode() {
    return this._sidenavMode;
  }

  set boxedLayout(val) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get boxedLayout() {
    return this._boxedLayout;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.heightView = window.innerHeight - 50
    if (window.innerWidth < 800) {
      this.sideNavOpened = false;
      this._sidenavMode = 'over';
    }
  }
}
