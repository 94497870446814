import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertsService } from '../../../modules/alerts/services/alerts.service';

@Component({
  selector: 'app-login-link',
  templateUrl: './login-link.component.html',
  styleUrls: ['./login-link.component.scss']
})
export class LoginLinkComponent implements OnInit {

  constructor(private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private alert: AlertsService,
              private router: Router) { }

  async ngOnInit() {
    try {
      console.log(this.activatedRoute.snapshot.params.email)
      if (this.auth.auth.auth.isSignInWithEmailLink(window.location.href)) {
        let email = this.activatedRoute.snapshot.params.email
        if (!email) {
          this.alert.open('Login', 'Please provide your email for confirmation')
        }
        const credential = await this.auth.auth.auth.signInWithEmailLink(email, window.location.href)
        const { isAdmin, isInternal } = await this.auth.claims.pipe(first()).toPromise()
        if (isAdmin) {
          this.router.navigateByUrl('/admin');
          return
        } else if(isInternal) {
          this.router.navigateByUrl('/');
          return
        }
      }
    } catch (error) {
      this.alert.open('Login', error)
    }
  }
}
