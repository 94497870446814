import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeagoPipe } from './pipes/timeago.pipe';

@NgModule({
  declarations: [
    TimeagoPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TimeagoPipe
  ]
})
export class TimeAgoModule { }
