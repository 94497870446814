import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FirestoreService } from '../../../../services/firestore.service';
import { Group } from '../../../../models/group';

@Component({
  selector: 'app-dashboard-charts-feedbacks-by-groups',
  templateUrl: './dashboard-charts-feedbacks-by-groups.component.html',
  styleUrls: ['./dashboard-charts-feedbacks-by-groups.component.scss']
})
export class DashboardChartsFeedbacksByGroupsComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl();
  filteredGroups: Observable<Group[]>;
  groups: Group[] = [];
  allGroups: Group[] = [];

  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private firestore: FirestoreService) {
    this.firestore.groups().valueChanges().subscribe(groups => {
      this.allGroups = groups
      this.filteredGroups = this.groupCtrl.valueChanges.pipe(startWith(null), map(group => {
        if (typeof group === 'string') { return this._filter(group) }
      }))
    })
  }

  ngOnInit() {
  }

  remove(group: Group): void {
    const index = this.groups.indexOf(group);

    if (index >= 0) {
      this.groups.splice(index, 1);
      this.groups =  Object.assign([], this.groups);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    const source = event.source;

    if (value) {
      this.groups.push(event.option.value);
      this.groupInput.nativeElement.value = '';
      this.groupCtrl.setValue(null);
    }
  }

  private _filter(value: string): Group[] {
    const filterValue = value.toLowerCase();
    return this.allGroups.filter(group => group.name.toLowerCase().indexOf(filterValue) === 0 && !this.groups.includes(group));
  }
}