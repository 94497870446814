import { Injectable, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable()
export class UploadService {

  @Output() file = new EventEmitter();

  constructor(public storage: AngularFireStorage) {}

  public get BaseRef() {
    return this.storage.ref('/')
  }
  CompanyImportUsers(idCompany: string) {
    return this.BaseRef.child('import-files').child(idCompany)
  }
  logoRef() {
    return this.BaseRef.child('logo')
  }
  moodsRef() {
    return this.BaseRef.child('moods')
  }
  settingsUserImportUsersRef() {
    return this.BaseRef.child('settings').child('users').child('import-users')
  }

  public upload(path, data, metadata) {
    return this.storage.upload(path, data, metadata)
  }

  public downloadURL(path) {
    return this.storage.ref(path).getDownloadURL()
  }

  public delete(path) {
    return this.storage.ref(path).delete()
  }
}
