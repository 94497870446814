import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;
  itemsPerPageLabel = 'Itens por página';
  nextPageLabel     = 'Próxima página';
  previousPageLabel = 'Página anterior';

  injectTranslateService(translate: TranslateService) {
      this.translate = translate;
      this.translate.onLangChange.subscribe(() => {
          this.translateLabels();
      });

      this.translateLabels();
  }

  getRangeLabel = function (page, pageSize, length) {
      const of = this.translate ? this.translate.instant('paginator.of') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 of ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ` ${of} ` + length;
  };

  async translateLabels() {
      this.itemsPerPageLabel = await this.translate.get('paginator.items_per_page').pipe(first()).toPromise();
      this.nextPageLabel = this.translate.instant('paginator.next_page');
      this.previousPageLabel = this.translate.instant('paginator.previous_page');
  }
}
