import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../../../../services/firestore.service';
import { Group } from '../../../../../models/group';
import { AuthService } from '../../../../../services/auth.service';
import { Organization } from '../../../../../models/organization';
import { first } from 'rxjs/operators';
declare let echarts: any;

@Component({
  selector: 'app-performance-charts-skills-ratting-sentiment-value',
  templateUrl: './performance-charts-skills-ratting-sentiment-value.component.html',
  styleUrls: ['./performance-charts-skills-ratting-sentiment-value.component.scss']
})
export class PerformanceChartsSkillsRattingSentimentValueComponent implements OnInit {

  groups: Observable<Group[]>
  organization: Organization
  chart

  constructor(
    private firestore: FirestoreService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.organization = await this.firestore.organization().valueChanges().pipe(first()).toPromise()
    this.groups = this.firestore.groups().valueChanges()
    this.data(this.organization)
  }

  isGroup(object: any): object is Group {
    return 'managers' in object;
  }
  
  async data(data?: Group | Organization) {
    let path = 'skills-rating-sentiment-value'
    var groupRef = this.firestore.stats().doc(path)
    var memberRef = this.firestore.usersStats(this.auth.getUid()).doc(path)

    if(this.isGroup(data)) {
      groupRef = this.firestore.groupsStats(data.id!!).doc(path)
      memberRef = this.firestore.membersStats(data.id!!, this.auth.getUid()).doc(path)
    }
    
    const member = await memberRef.valueChanges().pipe(first()).toPromise()
    const groupValue = await groupRef.valueChanges().pipe(first()).toPromise()
    this.chart = {
      title : {
        text: '',
        subtext: ''
      },
      tooltip : {
        trigger: 'axis'
      },
      legend: {
        orient : 'vertical',
        x : 'right',
        y : 'bottom',
      },
      toolbox: {
        show : true,
        feature : {
          saveAsImage : {
            show : true,
            title : 'Salvar',
            type : 'png',
          }
        }
      },
      radar : [
        member ? {
          radius: 120,
          indicator : Object.keys(member).map(i => member[i]).map(value => { return { name: value['name'], max: 1}})
        } : {
          radius: 120,
          indicator : Object.keys(groupValue).map(i => groupValue[i]).map(value => { return { name: value['name'], max: 1}})
        }
      ],
      calculable : true,
      series : [
        {
          name: '',
          type: 'radar',
          itemStyle: {
            normal: {
              areaStyle: {
                normal: {
                  opacity: 0.9,
                  color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                    {
                      color: '#B8D3E4',
                      offset: 0
                    },
                    {
                      color: '#72ACD1',
                      offset: 1
                    }
                  ])
                }
              }
            }
          },
          data : [
            {
              value : member ? Object.keys(member).map(i => member[i]).map(value => value['sentimentValue']/value['count']) : [],
              name : 'EU'
            },
            {
              value : groupValue ? Object.keys(groupValue).map(i => groupValue[i]).map(value => value['sentimentValue']/value['count']) : [],
              name : data.name
            },
          ]
        }
      ]
    }
  }
}
